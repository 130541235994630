import { graphql} from "gatsby";
import React from "react";
import {useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import { Hero } from "../components/Hero/Hero";
import { Card } from "../components/Card/Card";
import { Container } from "../components/Container/Container";
import { Column } from "../components/Column/Column";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;




const PrivacyPolicyPage = () => {
  const { t } = useTranslation();

  return (
    <Layout
      pageTitle={t("privacy-policy.hero.title")}
      description={t("privacy-policy.hero.subtitle")}
    >
      <Hero
        title={t("privacy-policy.hero.title")}
        description={t("privacy-policy.hero.subtitle")}
        image="privacy-policy/hero-privacy-policy.jpg"
      />
     <Container>
        <Column columns_mobile="12" columns_tablet="12" columns_desktop="12" custom_class="no-gutter@lg">
          <Card
            title={t("privacy-policy.tag-privacy.title")}
            description={t("privacy-policy.tag-privacy.text1")}
            image="privacy-policy/tag-privacy-policy.jpg"
          />

          <Card
            title={t("privacy-policy.risk-disclosure.title")}
            description={t("privacy-policy.risk-disclosure.text1")}
            image="privacy-policy/risk-disclosure.jpg"
            reverse
          />
        </Column>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicyPage;
